<template>
  <div
    v-if="visible"
    :class="['stock-edit-modal', showLocations ? 'high' : 'short']"
  >
    <div class="dialog-header">
      <el-icon class="icon-close" @click="handleCancel"><Close /></el-icon>
      <span>{{ $t('pages.products.detail_view.stock_modal.title') }}</span>
    </div>
    <single-location-select
      v-if="showLocations"
      v-model="location"
      class="px-6 w-full"
    />
    <div class="flex h-10 px-6 w-full">
      <div
        role="button"
        class="w-12 border border-r-0 rounded-l flex items-center justify-center text-sm bg-th-primary-light text-th-primary-gray"
        @click="decrease"
      >
        <el-icon><Minus /></el-icon>
      </div>
      <input
        id="rate"
        v-model="quantity"
        type="number"
        class="input-quantity flex-grow h-10 w-full border text-center focus:outline-none"
        :lang="$i18n.locale"
      />
      <div
        role="button"
        class="w-12 border border-l-0 rounded-r flex items-center justify-center text-sm bg-th-primary-light text-th-primary-gray"
        @click="increase"
      >
        <el-icon><Plus /></el-icon>
      </div>
    </div>

    <div class="dialog-footer">
      <el-button type="primary" @click="handleSave">
        {{ $t('common.interactions.buttons.save') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Decimal from 'decimal.js-light'
import SingleLocationSelect from '@/components/select/single-location-select'

export default {
  components: {
    SingleLocationSelect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showLocations: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      quantity: 0,
      location: null
    }
  },
  watch: {
    visible: function (newVal) {
      this.quantity = 0
      this.location = null
    }
  },
  methods: {
    increase() {
      this.quantity = new Decimal(this.quantity).plus(this.step).toNumber()
    },
    decrease() {
      this.quantity = new Decimal(this.quantity).minus(this.step).toNumber()
    },
    handleSave(done) {
      if (this.showLocations && !this.location) {
        return this.$message({
          type: 'error',
          message: this.$t(
            'pages.products.detail_view.stock_modal.missing_location'
          )
        })
      }

      this.$emit('close-edit-stock')

      this.$emit('save-stock', {
        qty: new Decimal(this.quantity).toNumber(),
        location: this.location
      })
    },
    handleCancel() {
      this.$emit('close-edit-stock')
    }
  }
}
</script>

<style scoped>
.stock-edit-modal {
  position: fixed;
  bottom: 30vh;
  left: 40vw;
  z-index: 1000;
  width: 348px;
  margin: auto;
  background: white;
  box-shadow: 0 0 3px grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.dialog-header {
  width: 100%;
  padding-bottom: 10px;
  box-shadow: 0 2px 4px 0 #a7abb1;
  padding: 27px 0;
  text-align: center;
}

.icon-close {
  position: absolute;
  left: 19px;
  cursor: pointer;
}

.el-input-number {
  width: 305px;
}

.el-button {
  min-width: 104px;
}

.dialog-footer {
  width: 100%;
  border-top: #979797 1px solid;
  text-align: center;
  padding: 10px 0;
}

.high {
  height: 286px;
}

.short {
  height: 217px;
}
</style>
