<template>
  <div>
    <el-select
      v-if="options.length > 0"
      v-model="selected"
      :disabled="readOnly"
      class="w-full"
      multiple
      collapse-tags
      collapse-tags-tooltip
    >
      <el-option
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selected: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>
*:deep(.el-input__suffix),
*:deep(.el-input__wrapper) {
  display: none;
}
*:deep(.el-select .el-input.is-disabled .el-input__wrapper) {
  cursor: pointer;
}
*:deep(.cell.el-tooltip) {
  padding: 0;
}
</style>
