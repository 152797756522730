<template>
  <th-modal
    :title="$t('pages.products.print.print_labels_legacy')"
    width="700px"
    name="print-legacy-dialog"
  >
    <template v-if="unprintables.length > 0" #subtitle class="flex">
      <span class="mr-1">
        <el-tooltip
          :content="$t('pages.products.all.legacy_print.cannot_print_tooltip')"
        >
          <el-icon style="color: #f56c6c"><Warning /></el-icon>
        </el-tooltip>
        {{ $t('pages.products.print.cannot_print') }}
      </span>
      <span class="font-bold">
        {{ unprintables.map((i) => i.name).join(', ') }}
      </span>
    </template>

    <!-- Barcode Type -->
    <div class="mb-4">
      <th-input-title
        :title="$t('pages.products.all.legacy_print.type_title')"
      />
      <div class="flex items-center">
        <el-radio-group v-model="typeSelect">
          <el-radio-button :label="0">
            {{ $t('pages.products.all.legacy_print.type.normal') }}
          </el-radio-button>
          <el-radio-button :label="2">
            {{ $t('pages.products.all.legacy_print.type.custom_id') }}
          </el-radio-button>
        </el-radio-group>
        <th-popover
          class="ml-2"
          :text="$t('pages.products.all.legacy_print.type_info')"
        />
      </div>
    </div>

    <!-- Label Shift -->
    <div class="mb-4">
      <th-input-title
        :title="$t('pages.products.all.legacy_print.label_shift_title')"
      />
      <el-slider v-model="labelShift" show-input :min="-500" :max="500" />
    </div>

    <!-- Table -->
    <el-table class="w-full rounded shadow-sm" :data="printables">
      <el-table-column type="expand">
        <template #default="props">
          <pre>{{ JSON.stringify(props.row, null, 2) }}</pre>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('pages.products.print.headers.name')"
        prop="name"
      />
      <el-table-column
        :label="$t('pages.products.print.headers.quantity')"
        prop="quantity"
      >
        <template #default="scope">
          <el-input
            v-model="scope.row.quantity"
            type="number"
            :min="0"
            :step="1"
          />
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <!-- Cancel -->
      <el-button @click="close">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Print -->
      <el-button
        type="primary"
        :disabled="!printables || !printables.length"
        @click="print"
      >
        {{ $t('common.interactions.buttons.print') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'LegacyProductsPrint',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    loading: false,
    typeSelect: 0, // 0 == normal code, 1 == legacy, 2 == custom_id
    labelShift: 0
  }),
  computed: {
    ...mapGetters({
      user: 'Auth/getUser'
    }),
    printables() {
      const printables = []

      this.items.forEach((item) => {
        if (
          !['product', 'linked_product', 'variant', 'linked'].includes(
            item.type
          )
        )
          return

        // check codes in normal & legacy mode
        if ([0, 1].includes(this.typeSelect)) {
          // we have to gather all codes to see if we end up with at least one
          const productCodes = []
          if (item.barcode && item.barcode.length) {
            productCodes.push(item.barcode)
          }
          if (item.codes && item.codes.length) {
            item.codes.forEach((c) => {
              if (c.data && c.data.length) productCodes.push(c.data)
            })
          }

          if (!productCodes.length) return
        }

        // check custom_id in custom_id mode
        if (
          this.typeSelect === 2 &&
          (!item.custom_id || !item.custom_id.length)
        )
          return

        printables.push({ quantity: 1, ...item })
      })

      return printables
    },
    unprintables() {
      // we have to base comparison on IDs, as object comparison doesn't work here
      const printableIds = this.printables.map((i) => i.id)
      return this.items.filter((i) => !printableIds.includes(i.id))
    }
  },
  methods: {
    close() {
      this.$emit('close-requested')
      this.$thModal.hide('print-legacy-dialog')
    },

    /**
     * Creates a print job for each product item
     */
    async print() {
      try {
        const items = []

        this.printables.forEach((item) => {
          const cId = item.custom_id
          const options = item.attributes
            ? Object.values(item.attributes).join(', ')
            : '-'

          // gather barcodes of product
          const productCodes = []
          if (item.barcode && item.barcode.length) {
            productCodes.push(item.barcode)
          }
          if (item.codes && item.codes.length) {
            productCodes.push(...item.codes.map((c) => c.data))
          }

          // array holding final codes
          // NOTE: array will never end up empty, as we check for this when computing `printables`
          const codes = []

          switch (this.typeSelect) {
            case 0: // normal
              for (const code of productCodes) {
                codes.push(code)
              }
              break
            case 1: // legacy
              for (const code of productCodes) {
                if (code && code.length) codes.push(`*S${code.padStart(13, 0)}`)
              }
              break
            case 2: // custom_id
              if (cId && cId.length) codes.push(cId)
              break
            default:
              throw new Error(
                `unknown code type selection '${this.typeSelect}'. only 0-2 is supported!`
              )
          }

          const name = item.name ? item.name.substring(0, 16) : '-'

          items.push({
            name,
            options: options.substring(0, 16),
            price: this.getFormattedPrice(item),
            codes,
            quantity: item.quantity,
            label_shift: this.labelShift
          })
        })

        // warn if one of the codes is >17 characters long
        const tooLongCodeProducts = []
        items.forEach((item) => {
          if (item.codes.find((c) => c.length > 17))
            tooLongCodeProducts.push(item.name)
        })

        if (tooLongCodeProducts.length) {
          const message = `${this.$t(
            'pages.products.all.legacy_print.confirm.description'
          )}:\n\n${tooLongCodeProducts.join(', ')}`

          try {
            await this.$confirm(
              message,
              this.$t('common.delete_confirm.title'),
              {
                confirmButtonText: this.$t('common.interactions.buttons.ok'),
                cancelButtonText: this.$t('common.interactions.buttons.cancel'),
                type: 'warning',
                customClass: 'legacy-print-confirm-custom-class'
              }
            )
          } catch (e) {
            // user cancelled, so we just return
            return
          }
        }

        const target = `https://europe-west2-tillhub-scarif-huurrugh.cloudfunctions.net/api/${this.user}/jobs/create`

        await axios.post(target, { items })

        // request succeeded, show legacy print page in new tab/window
        const url = `https://dashboard.tillhub.de/labelprint1/index.html?uuid=${this.user}`
        window.open(url, '_blank')

        // close dialog
        this.close()
      } catch (e) {
        const errMsg = this.$t(
          'pages.products.all.legacy_print.errors.print_fail'
        )
        this.$logException(e, { message: `${errMsg}: ${e.message || e}` })
      }
    },
    /**
     * Formats the price. Based on a formatter implemented in `../all.vue`
     * @param item - product item
     * @returns {string}
     */
    getFormattedPrice(item) {
      if (
        item.prices &&
        item.prices.default_prices &&
        Array.isArray(item.prices.default_prices)
      ) {
        // try to use default currency from configuration. if that fails, just take the first entry of default_prices
        const defaultCurrency = this.$store.getters[
          'Config/getCurrentDefaultCurrency'
        ]
        let price

        if (!defaultCurrency) {
          // TODO: maybe instead of taking first element from default_prices, ask user for selection instead
          price = item.prices.default_prices[0]
        } else {
          price = item.prices.default_prices.find((item) => {
            return item.currency === defaultCurrency
          })
        }

        if (
          !price ||
          !price.amount ||
          !Number.isFinite(price.amount.gross) ||
          !price.currency
        ) {
          return '-'
        }

        return this.$formatCurrency(price.amount.gross, price.currency)
      }

      return '-'
    }
  }
}
</script>
