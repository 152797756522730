<template>
  <th-page-wrapper>
    <quick-view-content
      v-if="!!quickViewItem"
      data-testid="products-quick-view"
      :product="quickViewItem"
      @refresh-datatable="$refs.table.refresh"
      @close="quickViewItem = null"
    />

    <products-list-empty
      v-if="!productsExists"
      :template-href="templateHref"
      @refresh="refresh"
    />

    <th-datatable
      v-show="productsExists"
      ref="table"
      :key="$route.fullPath"
      :headers="headers"
      :do-route="!hasQuickView"
      :do-route-dbl-click="hasUpdatePermissions"
      route-base="/products/manager"
      do-slower-row-dbl-click
      do-route-filters
      multiple-select
      sortable
      :show-operations="false"
      :operations="operations"
      :resource-limit="100"
      resource="products"
      :resource-query="{ query: resourceQuery }"
      :force-meta-check="true"
      :loading-text="loadingText"
      headers-filterable
      :headers-default-hide="headersDefaultHide"
      :transform-table-data="
        (data) => data.map((row) => ({ ...row, children: undefined }))
      "
      :transform-fetched-data="transformFetchedData"
      transform-fetched-meta-allowed
      :headers-config="headersConfig"
      show-search-filter
      :search-filters="filtersList"
      :buttons="computedButtons"
      :retry-options="retryOptions()"
      :extend-search="extendSearch"
      :document-export="documentExport"
      :force-all-export-columns="true"
      export-document-type="ProductsExport"
      :header-name-to-export-column-map="headerNameToExportColumnMap"
      :export-filename-prefix="$t('pages.products.title')"
      :handle-export="handleExport"
      custom-edit-path="/products/manager"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectAll"
      @row-click="handleRowClick"
      @row-dblclick="handleRowDoubleClick"
      @loading-error="handleLoadingError"
      @headers-config="handleHeadersConfig"
    >
      <template #actions class="actions">
        <actions
          :all-selected="allSelected"
          :selected-items="selectedItems"
          @delete-requested="handleDelete"
        />
      </template>
      <template #tools>
        <product-importer
          v-if="$checkPermissions({ scopes: ['products:bulk_import'] })"
          class="button-text-color"
          :resources="resources"
          @refresh="refresh"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import safeGet from 'just-safe-get'
import typeOf from 'just-typeof'
import sortBy from 'just-sort-by'
import { mapGetters } from 'vuex'
import debounce from 'debounce'
import QuickViewContent from './components/quick-view-content'
import Actions from './components/actions'
import ProductsListEmpty from './empty'
import ProductImporter from './components/product-importer'
import LocationCell from './v2/components/locations'
import TemplateFileButton from '@components/importer/template-file-button'
import { isPurchaseItem } from '@/utils/products'
import { waitForData } from '@/utils/general'
import { useProductsStore } from '@/store/products'
import { useMessagesStore } from '@/store/messages'
import { useExportsStore } from '@/store/exports'
import { getSortedOptions } from './v2/helpers/helpers.js'
import ProductNameCell from './components/product-name-cell'

export default {
  name: 'ProductsAll',
  metaInfo() {
    return {
      title: this.$t('pages.products.title')
    }
  },
  components: {
    ProductImporter,
    QuickViewContent,
    ProductsListEmpty,
    Actions,
    TemplateFileButton
  },
  setup() {
    const productsStore = useProductsStore()
    return { productsStore }
  },
  data() {
    return {
      templateHref:
        'https://storage.googleapis.com/tillhub-dashboard/templates/vorlage_artikelliste.xlsx',
      resources: {},
      product: undefined,
      permissions: {
        create: {
          scopes: ['products:create']
        },
        edit: {
          scopes: ['products:update']
        },
        delete: {
          scopes: ['products:delete']
        }
      },
      headersDefaultHide: ['brand', '_purchase_price', '_barcodes', 'sku'],
      selectedItems: [],
      quickViewItem: null,
      allSelected: false,
      moreThanOneProduct: false,
      loadingText: '',
      buttons: [
        {
          scopes: ['products:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.forms.labels.new'),
          clickHandler: this.handleNewRequest
        }
      ],
      headerNameToExportColumnMap: {
        _attributes_names: 'attributes',
        _barcodes: 'barcodes',
        _type_label: 'type',
        _product_group_label: 'product_group_name',
        _price_label: 'default_price',
        _purchase_price: 'purchase_price',
        _stock_info_label: 'stock',
        _account_label: 'revenue_account',
        _tax_label: 'tax_name'
      }
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation',
      timeZone: 'Config/getTimeZone',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      localConfiguration: 'Config/getLocalConfiguration',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    productsExists() {
      // Quick fix for https://tillhub.atlassian.net/browse/BE-968
      // Real problem comes from API, returning a meta with count 0
      // TODO: delete moreThanOneProduct when fixed in API
      return this.productsStore.productsCount > 0 || this.moreThanOneProduct
    },
    headersConfig() {
      return (
        safeGet(this.localConfiguration, 'settings.headerFilters.products') ||
        {}
      )
    },
    searchLimit() {
      return (
        safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'settings.search.products.limit'
        ) || 20
      )
    },
    hasQuickView() {
      return (
        safeGet(
          this.clientAccountConfiguration,
          'ui_configurations.dashboard.products.list.quick_view_enabled'
        ) !== false
      )
    },
    resourceQuery() {
      return {
        deleted: false,
        extended: true,
        location: this.currentLocation || undefined,
        location_strict: this.currentLocation ? true : undefined,
        exclude_system_products: true,
        type: [
          'product',
          'composed_product',
          'voucher',
          'linked',
          'linked_product',
          'variant',
          'variant_product'
        ],
        original_product: true,
        opt_out: 'performance',
        limit: 100,
        field: ['prices.branch_prices', 'prices.time_based_prices']
      }
    },
    isSingleSelected() {
      return this.selectedItems.length === 1
    },
    canSelectLocations() {
      //undefined current location === all locations
      return !this.currentLocation
    },
    filtersList() {
      //user can select location only if he has the permissions for all locations
      const locations = this.canSelectLocations
        ? [
            {
              name: 'branch_group',
              type: 'multiselect',
              collapseTags: true,
              label: this.$t('nav.main.items.resources.branch_groups'),
              options: getSortedOptions(this.resources?.branchGroups),
              filterable: true
            },
            {
              name: 'locations',
              type: 'multiselect',
              collapseTags: true,
              label: this.$t('nav.main.items.resources.branches'),
              options: getSortedOptions(this.resources?.branches),
              filterable: true
            }
          ]
        : []

      return [
        {
          name: 'custom_id',
          type: 'remote-search-select',
          label: this.$t('pages.products.all.filters.custom_id.label'),
          placeholder: this.$t('common.inputs.placeholders.search'),
          resource: 'products',
          optionsValue: 'custom_id',
          computedFields: ['custom_id', 'name'],
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            exclude_system_products: true,
            type: [
              'product',
              'composed_product',
              'voucher',
              'linked',
              'linked_product',
              'variant',
              'variant_product'
            ],
            original_product: true
          })
        },
        {
          name: 'product_group',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.products.all.filters.product_group.label'),
          options: getSortedOptions(this.resources?.product_groups),
          filterable: true
        },
        ...locations,
        {
          name: 'account',
          type: 'remote-search-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.products.all.headers.account'),
          computedFields: ['fa_account_number', 'name'],
          resource: 'accounts',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            type: 'revenue'
          })
        },
        {
          name: 'business_partner_id',
          type: 'select',
          label: this.$t('common.resource.supplier.singular'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options: this.suppliersOptions
        },
        {
          name: 'tax',
          type: 'remote-search-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.products.all.headers.vat'),
          computedFields: ['fa_account_number', 'name'],
          resource: 'taxes',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false
          })
        },
        {
          name: 'type',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.products.all.headers.type'),
          filterable: true,
          options: [
            {
              label: this.$t('common.products.types.product.title'),
              value: 'product'
            },
            {
              label: this.$t('common.products.types.variant_product.title'),
              value: 'variant_product'
            },
            {
              label: this.$t('common.products.types.variant.title'),
              value: 'variant'
            },
            {
              label: this.$t('common.products.types.linked_product.title'),
              value: 'linked_product'
            },
            {
              label: this.$t('common.products.types.composed_product.title'),
              value: 'composed_product'
            }
          ]
        },
        {
          name: 'tags',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.tags.title'),
          options: getSortedOptions(this.resources?.tags),
          filterable: true
        },
        {
          name: 'attributes',
          type: 'input',
          label: this.$t('pages.products.all.filters.attributes.label')
        },
        {
          name: 'attributes_value',
          type: 'input',
          label: this.$t('pages.products.all.filters.attributes_value.label'),
          disable: (filters) => {
            return !safeGet(filters, 'attributes.value')
          }
        },
        {
          name: 'is_service',
          type: 'select',
          label: this.$t('pages.products.edit.form.service.title'),
          options: this.yesNoOptions,
          filterable: true
        },
        {
          name: 'purchasable',
          type: 'select',
          label: this.$t('pages.products.edit.form.types.purchase_item'),
          options: this.yesNoOptions,
          filterable: true
        },
        {
          name: 'active',
          type: 'select',
          label: this.$t('pages.products.all.filters.active.title'),
          options: this.yesNoOptions,
          filterable: true
        },
        {
          name: 'sku',
          type: 'input',
          label: this.$t('pages.products.all.filters.sku.label')
        }
      ]
    },
    headers() {
      return [
        {
          field: 'name',
          label: this.$t('pages.products.all.headers.name'),
          minWidth: 250,
          truncate: true,
          customRowComponent: ProductNameCell
        },
        {
          field: '_attributes_names',
          label: this.$t('pages.products.all.headers.attributes'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: '_barcodes',
          label: this.$t('pages.products.properties.barcodes.label'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'custom_id',
          label: this.$t('pages.products.all.headers.custom_id'),
          fallback: '-',
          minWidth: 180,
          truncate: true
        },
        {
          field: '_type_label',
          label: this.$t('pages.products.all.headers.type'),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        ...this.locationHeader,
        {
          field: 'business_partner_company_name',
          label: this.$t('common.resource.supplier.singular'),
          minWidth: 150,
          truncate: true,
          formatter: (row) =>
            safeGet(row, 'business_partners', [])
              .map((bp) => bp.company_name)
              .join(', ') || '-'
        },
        {
          field: '_product_group_label',
          label: this.$t('pages.products.all.headers.product_group'),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: '_price_label',
          label: this.$t('pages.products.all.headers.price'),
          fallback: '-',
          align: 'right',
          sortType: 'currency',
          truncate: true,
          minWidth: 120
        },
        {
          field: '_purchase_price',
          label: this.$t(
            'pages.products.edit.form.pricing.common.headers.purchase_price'
          ),
          fallback: '-',
          align: 'right',
          sortType: 'currency',
          truncate: true,
          minWidth: 120
        },
        {
          field: 'brand',
          label: this.$t('pages.products.all.headers.brand'),
          fallback: '-',
          truncate: true,
          minWidth: 120
        },
        {
          field: '_stock_info_label',
          label: this.$t('pages.products.all.headers.stock_info'),
          fallback: '-',
          align: 'right',
          sortType: 'number',
          truncate: true,
          minWidth: 120
        },
        {
          field: '_account_label',
          label: this.$t('pages.products.all.headers.account'),
          fallback: '-',
          align: 'right',
          truncate: true,
          minWidth: 140
        },
        {
          field: '_tax_label',
          label: this.$t('pages.products.all.headers.vat'),
          fallback: '-',
          align: 'right',
          truncate: true,
          minWidth: 120
        },
        {
          field: 'sku',
          label: this.$t('pages.products.properties.sku.label'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        }
      ]
    },
    locationHeader() {
      return this.canSelectLocations
        ? [
            {
              field: 'locations',
              label: this.$t('nav.main.items.resources.branches'),
              fallback: '-',
              minWidth: 200,
              truncate: true,
              customRowComponent: LocationCell,
              resources: this.resources
            }
          ]
        : []
    },
    yesNoOptions() {
      return [
        {
          label: this.$t('pages.products.all.filters.active.all_active'),
          value: 'true'
        },
        {
          label: this.$t('pages.products.all.filters.active.all_inactive'),
          value: 'false'
        }
      ]
    },
    suppliersOptions() {
      return sortBy(this.resources.suppliers || [], 'companyName').map(
        ({ id, companyName }) => {
          return {
            value: id,
            label: companyName
          }
        }
      )
    },
    operations() {
      const permissions =
        typeOf(this.permissions) === 'object' ? this.permissions : {}
      return Object.entries(permissions).reduce(
        (ops, [action = '', { scopes = [] }]) => {
          if (!action) return ops
          const isGuarded = scopes && scopes.length
          const permissionVal = isGuarded
            ? this.$checkPermissions({ scopes })
            : true

          if (typeOf(permissionVal === 'boolean')) {
            ops[action] = permissionVal
          }

          return ops
        },
        {}
      )
    },
    documentExport() {
      return this.$checkPermissions({ scopes: ['products:export'] })
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    hasUpdatePermissions() {
      return this.$checkPermissions({ scopes: ['products:update'] })
    },
    translations() {
      return {
        voucher: this.$t('common.products.types.voucher.title'),
        variant_product: this.$t('common.products.types.variant_product.title'),
        variant: this.$t('common.products.types.variant.title'),
        product: this.$t('common.products.types.product.title'),
        linked_product: this.$t('common.products.types.linked_product.title'),
        linked: this.$t('common.products.types.linked.title'),
        composed_product: this.$t(
          'common.products.types.composed_product.title'
        )
      }
    }
  },
  watch: {
    'parsedQuery.custom_id': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.fetchProduct(newValue)
        }
      }
    }
  },
  async mounted() {
    try {
      await this.fetchResources()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })

    this.productsStore.clearSelectedItems()
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.products.title')
        })
      })
    },
    handleNewRequest() {
      this.$ampli.eventWithBaseProps('productNewButtonClick')
      this.$router.push({ name: 'products-new' })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.products',
        value: config || {}
      })
    },
    handleSelectionChange(val) {
      if (this.allSelected) this.allSelected = false
      this.selectedItems = val
    },
    handleSelectAll(val) {
      this.allSelected = !!val.length
    },
    handleRowClick: debounce(function (v = {}) {
      if (!this.hasQuickView) {
        return
      }
      this.$ampli.eventWithBaseProps('productRowClick')
      this.quickViewItem = v.id
      this.$nextTick(() => {
        this.$thModal.show('quick-view')
      })
    }, 150),
    handleRowDoubleClick() {
      this.$ampli.eventWithBaseProps('productRowDoubleClick')
    },
    async fetchResources() {
      const resourceList = [
        {
          resource: 'product_groups',
          errorHandler: (error) =>
            this.getFetchingResourceErrorHandler(
              error,
              this.$t('common.resource.product_group.plural')
            ),
          fallback: () => ({ data: [], metadata: { count: 0 } })
        },
        {
          resource: 'taxes',
          errorHandler: (error) =>
            this.getFetchingResourceErrorHandler(
              error,
              this.$t('common.resource.tax.plural')
            ),
          fallback: () => ({ data: [], metadata: { count: 0 } })
        },
        {
          resource: 'tags',
          errorHandler: (error) =>
            this.getFetchingResourceErrorHandler(
              error,
              this.$t('common.resource.tag.plural')
            ),
          fallback: () => ({ data: [], metadata: { count: 0 } })
        },
        {
          prop: 'accounts',
          handler: () =>
            th.accounts().getAll({ type: 'revenue', deleted: false }),
          errorHandler: (error) =>
            this.getFetchingResourceErrorHandler(
              error,
              this.$t('common.resource.account.plural')
            ),
          fallback: () => ({ data: [], metadata: { count: 0 } })
        } /*,
        {
          resource: 'suppliers',
          errorHandler: (error) =>
            this.getFetchingResourceErrorHandler(
              error,
              this.$t('common.resource.supplier.plural')
            ),
          fallback: () => ({ data: [] })
        }*/
      ]

      if (this.canSelectLocations) {
        resourceList.push(
          {
            resource: 'branchGroups',
            query: { deleted: false },
            errorHandler: (error) =>
              this.getFetchingResourceErrorHandler(
                error,
                this.$t('common.resource.branch.plural')
              ),
            fallback: () => ({ data: [], metadata: { count: 0 } })
          },
          {
            resource: 'branchesV1',
            errorHandler: (error) =>
              this.getFetchingResourceErrorHandler(
                error,
                this.$t('common.resource.branch.plural')
              ),
            fallback: () => ({ data: [], metadata: { count: 0 } })
          }
        )
      }

      this.resources = await this.$resourceFetch(...resourceList)
      this.resources['branches'] = this.resources?.branchesV1
    },
    refresh() {
      this.$refs.table.refresh()
      this.productsStore.checkProductsCount()
    },
    getFetchingResourceErrorHandler(error, resource) {
      this.$logException(error, {
        trackError: false,
        message: this.$t('common.error.action.read.single', {
          resource
        })
      })
    },
    async fetchLinkedServices(ids) {
      try {
        const inst = th.products()
        const { data = {} } = await inst.getAll({
          query: {
            is_reservations_service: true,
            deleted: false,
            linked_product_id: ids
          }
        })

        return data.length
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    async handleDelete(payload) {
      const selectedItemsIds = Array.from(this.selectedItems, (e) => e.id)
      const linkedServices = await this.fetchLinkedServices(selectedItemsIds)
      const linkedServicesWarning =
        linkedServices > 0
          ? this.$t('pages.products.all.delete.warning.linked_services', {
              resource: linkedServices
            })
          : ''

      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.delete_confirm.object_names.products'),
        'name',
        null,
        linkedServicesWarning
      )
      if (confirm) {
        this.$ampli.eventWithBaseProps('productBulkDeleted')
        if (payload.length > 1) {
          return this.handleBulkDelete(this.selectedItems)
        }
        this.deleteResource(payload)
      }
    },

    async deleteResource(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.product.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.product.singular')
      })

      try {
        const inst = th.products()
        await inst.delete(payload[0].id, {
          delete_dependencies:
            payload[0].type === 'variant_product' || undefined
        })
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.products()

      const operations = items.map((item) => {
        return () =>
          inst.delete(item.id, {
            delete_dependencies: item.type === 'variant_product'
          })
      })

      const label = this.$t('common.error.action.delete.multiple', {
        resources: this.$t('common.resource.product.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    retryOptions() {
      const loadingMsg = this.$t('common.loading.long')

      return {
        retries: 4,
        onFailedAttempt: (err) => {
          if (err.attemptNumber === 2) {
            this.loadingText = loadingMsg
          }
        }
      }
    },
    async transformFetchedData(data) {
      // Quick fix for https://tillhub.atlassian.net/browse/BE-968
      // Real problem comes from API, returning a meta with count 0
      // TODO: delete moreThanOneProduct when fixed in API
      this.moreThanOneProduct = !!data.length
      await waitForData(
        () =>
          this.resources.accounts &&
          this.resources.product_groups &&
          this.resources.taxes
      )

      return data.map((item) => {
        if (item.type) {
          item._type_label = this.translations[item.type] || '–'
          if (isPurchaseItem(item)) {
            item._type_label = this.$t(
              'pages.products.edit.form.types.purchase_item'
            )
          }
        }

        if (item.product_group && this.resources.product_groups) {
          const productGroup =
            this.resources.product_groups.find(
              (pgItem) => pgItem.id === item.product_group
            ) || {}
          item._product_group_label = productGroup.name || '–'
        }

        if (item.account && this.resources.accounts) {
          const account =
            this.resources.accounts.find(
              (account) => account.id === item.account
            ) || {}
          item._account_label = account.name || '–'
        }

        if (item.tax && this.resources.taxes) {
          const tax =
            this.resources.taxes.find((tax) => tax.id === item.tax) || {}
          item._tax_label = tax.name || '–'
        }

        if (Array.isArray(item?.prices?.default_prices)) {
          const currentCurrencyPrice = item.prices.default_prices.find(
            (item) => {
              return item.currency === this.defaultCurrency
            }
          )

          if (
            !currentCurrencyPrice ||
            !currentCurrencyPrice.amount ||
            !Number.isFinite(currentCurrencyPrice.amount.gross)
          ) {
            item._price_label = '-'
          } else {
            item._price_label = this.$formatCurrency(
              currentCurrencyPrice.amount.gross,
              this.defaultCurrency
            )
          }

          // Set purchase price
          if (Number.isFinite(currentCurrencyPrice?.purchase_price)) {
            item._purchase_price = this.$formatCurrency(
              currentCurrencyPrice.purchase_price,
              this.defaultCurrency
            )
          } else {
            item._purchase_price = '-'
          }
        }

        if (item.stockable === false) {
          item._stock_info_label = '–'
        } else if (item.stock_info) {
          if (this.currentLocation) {
            const stockPath =
              item.type === 'variant' ? 'summary.locations' : 'all.locations'

            const stockLocations = safeGet(item.stock_info, stockPath) || []
            const currentLocationStock =
              stockLocations.find(
                (location) => location.id === this.currentLocation
              ) || {}
            item._stock_info_label = Number.isFinite(currentLocationStock.qty)
              ? `${currentLocationStock.qty}`
              : '–'
          } else if (
            item.type === 'variant_product' &&
            Number.isFinite(safeGet(item.stock_info, 'all.total.qty'))
          ) {
            // we have collected cumulated stock for variant products
            item._stock_info_label = `${safeGet(
              item.stock_info,
              'all.total.qty'
            )}`
          } else if (
            Number.isFinite(safeGet(item.stock_info, 'summary.total.qty'))
          ) {
            // TODO: be more informative here, e.g. "low in location"
            item._stock_info_label = `${safeGet(
              item.stock_info,
              'summary.total.qty'
            )}`
          }
        }

        // Add attributes list for Variants
        if (item?.attributes && Object.keys(item?.attributes).length) {
          let attributes = []
          Object.keys(item.attributes).forEach((option) => {
            attributes = attributes.concat(item.attributes[option])
          })
          item._attributes_names = attributes.join(' | ')
        }

        // Add list of barcodes
        if (item?.barcodes?.length) {
          item._barcodes = item.barcodes.join(', ')
        }

        // Add attributes list for Non Variants
        if (item?.options?.length) {
          let attributes = []
          item.options.forEach((option) => {
            Object.keys(option).forEach((attribute) => {
              attributes = attributes.concat(option[attribute])
            })
          })
          // Filter is a fix for null attributes
          // https://tillhub.atlassian.net/browse/DAS-1264
          item._attributes_names = attributes
            .filter((attribute) => attribute)
            .join(' | ')
        }

        return item
      })
    },
    extendSearch(filters) {
      return { q: filters.q, types: filters.type, limit: this.searchLimit }
    },
    async fetchProduct(product) {
      try {
        const { data } = await this.$resourceFetch({
          resource: 'products',
          query: { custom_id: product }
        })

        this.product = data?.[0]
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    async handleExport(query) {
      this.$ampli.eventWithBaseProps('productExportButtonClick')
      try {
        const { products = [] } = await this.$resourceFetch({
          resource: 'products',
          handler: () => th.products().getAll(query)
        })

        const exportId = products[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.products.title',
            date: new Date(),
            action: {
              entity: 'products',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.products.title')
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.actions {
  align-items: center;
  margin-right: 10px;
}

.disabled {
  opacity: 0.5;
}
.actions-button.blocked {
  pointer-events: none;
}

.product-type-ux-filter :deep(.el-radio-group *) {
  outline: none;
  user-select: none;
  height: var(--action-button-height);
}

.product-type-ux-filter :deep(.el-radio-group .el-radio-button__inner) {
  display: flex;
  align-content: center;
  align-items: center;
}

.product-type-ux-filter {
  white-space: nowrap;
}

.tools > div {
  display: flex;
  align-items: flex-end;
}
</style>
