<template>
  <th-modal
    name="quick-view"
    :title="payload.name"
    width="800px"
    @close="close"
  >
    <div v-loading="loading">
      <section class="upper-section">
        <div class="image-container">
          <th-image
            cover
            width="100%"
            height="100%"
            position="center"
            :src="image"
          />
          <div
            v-if="payload.default_tile_color"
            class="tile-color"
            :style="{ backgroundColor: payload.default_tile_color || 'white' }"
          />
        </div>
        <div class="item-text">
          <p
            v-text="
              payload.summary ||
              $t('common.data.no_data_for', {
                property: $t(
                  'pages.products.edit.form.properties.summary.label'
                )
              })
            "
          />
          <p
            v-text="
              payload.description ||
              $t('common.data.no_data_for', {
                property: $t(
                  'pages.products.edit.form.properties.description.label'
                )
              })
            "
          />
        </div>
      </section>

      <section class="middle-section">
        <el-row :gutter="20" justify="space-between" class="row">
          <el-col :span="8">
            <span>{{ $t('pages.products.detail_view.sku') }}:</span>
            <span class="row-value">
              {{ payload.sku || '–' }}
            </span>
          </el-col>
          <el-col :span="8">
            <span>{{ $t('pages.products.detail_view.brand') }}:</span>
            <span class="row-value">
              {{ payload.brand || '–' }}
            </span>
          </el-col>
          <el-col :span="8">
            <span>{{ $t('pages.products.detail_view.number') }}:</span>
            <span class="row-value">
              {{ payload.custom_id || '–' }}
            </span>
          </el-col>
        </el-row>

        <el-row :gutter="20" justify="space-between" class="row">
          <el-col :span="8">
            <span>{{ $t('pages.products.detail_view.location') }}:</span>
            <span v-if="showSingleLocation" class="row-value">
              {{ locationName || '–' }}
            </span>
            <el-tooltip v-else effect="dark" placement="bottom-start">
              <template #content>
                <div>
                  <span v-for="location in locations" :key="location.id">
                    {{ location.name || location.branch_number || '–' }} <br />
                  </span>
                </div>
              </template>
              <span class="row-value">
                {{ locationName || '–' }}
              </span>
            </el-tooltip>
          </el-col>
          <el-col :span="8">
            <span>{{ $t('pages.products.detail_view.ref_id') }}:</span>
            <span class="row-value">
              {{ payload.external_reference_id || '–' }}
            </span>
          </el-col>
        </el-row>
      </section>

      <section v-if="showTable && !loading" class="lower-section">
        <standard-table
          v-if="isStandAloneProduct"
          :product="payload"
          @refresh-datatable="$emit('refresh-datatable')"
        />
        <parent-table
          v-if="isParentProduct"
          :product="payload"
          @refresh-datatable="$emit('refresh-datatable')"
        />
      </section>
      <section v-if="loading" class="lower-section" />
    </div>

    <template #footer>
      <el-button type="primary" @click="clickEditButton">
        {{ $t('pages.products.all.quick_view.labels_view') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import { defaultImage } from '../product-default-image.js'
import ParentTable from './parent-table'
import StandardTable from './standard-table'
import { productType } from '@/utils/products'

export default {
  components: {
    ParentTable,
    StandardTable
  },
  props: {
    product: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      payload: {},
      loading: false,
      locations: []
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    image() {
      if (this.payload.images && this.payload.images['1x'])
        return this.payload.images['1x']

      return defaultImage
    },
    isStandAloneProduct() {
      return (
        this.payload.type === productType.PRODUCT ||
        this.payload.type === productType.VARIANT ||
        this.payload.type === productType.LINKED_PRODUCT
      )
    },
    isParentProduct() {
      return this.payload.type === productType.VARIANT_PRODUCT
    },
    showTable() {
      return (
        this.payload.id && (this.isParentProduct || this.isStandAloneProduct)
      )
    },
    locationName() {
      if (!this.locations || !this.locations.length) {
        return null
      } else if (this.locations.length === 1) {
        const locationData = this.locations[0]
        return locationData.name || locationData.branch_number || null
      } else {
        return this.$t('pages.products.detail_view.multiple')
      }
    },
    showSingleLocation() {
      return !this.locations || this.locations.length <= 1
    },
    showEditButton() {
      return this.$checkPermissions({ scopes: ['products:update'] })
    }
  },
  watch: {
    product: {
      immediate: true,
      handler() {
        this.fetchProduct()
      }
    }
  },
  methods: {
    async fetchProduct() {
      if (!this.product) return

      try {
        this.loading = true
        this.payload = {}
        const { data } = await th.products().get(this.product)
        await this.getLocations(data.locations)
        this.payload = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close')
    },
    clickEditButton() {
      this.$ampli.eventWithBaseProps('productEditButtonClick')
      this.$router.push({
        name: 'products-edit',
        params: { id: this.product }
      })
    },
    async getLocations(locations) {
      if (Array.isArray(locations)) {
        try {
          const res = await this.$resourceFetch('branchesV1')
          const branches = res.branchesV1

          this.locations = branches.filter((item) =>
            locations.includes(item.id)
          )
        } catch (err) {
          this.$logException(err, { trackError: false })
        }
      }
    }
  }
}
</script>

<style scoped>
.image-container {
  min-width: 140px;
  max-width: 140px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  overflow: auto;
  position: relative;
}

.tile-color {
  box-sizing: border-box;
  border-top: 1px solid #d8d8d8;
  height: 10%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.upper-section {
  display: flex;
}

.item-text :deep(p) {
  margin: 10px 0;

  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.item-text :deep(p:first-of-type) {
  margin: 0;
}

.middle-section,
.lower-section {
  margin-top: 1rem;
}

.lower-section {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  min-height: 346px;
}

.item-text {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}

.row-value {
  margin-left: 10px;
}

.tabs :deep(.el-tabs__item) {
  font-size: 18px;
}

.lower-section :deep(.el-pagination) {
  background: white;
  padding: 0.5rem !important;
}
</style>
