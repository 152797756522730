<template>
  <div>
    <datatable-cell-multiselect
      v-if="locationGroups.length > 0"
      v-model="locationGroups"
      :options="locationGroupOptions"
    />
    <datatable-cell-multiselect
      v-if="locations.length > 0"
      v-model="locations"
      :options="locationOptions"
    />
  </div>
</template>

<script>
import DatatableCellMultiselect from '@/components/datatable/components/datatable-cell-multiselect.vue'
export default {
  components: {
    DatatableCellMultiselect
  },
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    row() {
      return this.scope?.row || {}
    },
    locationGroups() {
      return this.row.branch_groups || []
    },
    locations() {
      return this.row.locations || []
    },
    locationOptions() {
      return (this.resources?.branches || []).map((branch) => {
        return {
          label: branch.name,
          value: branch.id
        }
      })
    },
    locationGroupOptions() {
      return (this.resources?.branchGroups || []).map((group) => {
        return {
          label: group.name,
          value: group.id
        }
      })
    }
  }
}
</script>

<style scoped>
*:deep(.el-input__suffix),
*:deep(.el-input__wrapper) {
  display: none;
}
*:deep(.el-select .el-input.is-disabled .el-input__wrapper) {
  cursor: pointer;
}
*:deep(.cell.el-tooltip) {
  padding: 0;
}
</style>
