export function findSellingPrice(priceObject = [], currency, locale) {
  const prices = priceObject.find((p) => p.currency === currency) || {}
  const sellingPrice = prices.amount && prices.amount.gross

  if (!sellingPrice || !Number.isFinite(sellingPrice)) return null

  if (!currency) return '-'
  // NOTE: this needs binding this
  return this.$formatCurrency(sellingPrice, currency)
}

export function formatStock(value) {
  const num = parseFloat(value)
  if (!Number.isFinite(num)) {
    return '-'
  } else {
    return this.$formatNumber(num)
  }
}
