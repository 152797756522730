<template>
  <span>
    <img
      v-if="image"
      :src="image"
      height="32"
      width="32"
      class="inline-block mr-2"
    />
    {{ scope.row.name || '-' }}
  </span>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { productPlaceholderImage } from './product-placeholder-image.js'

const props = defineProps({
  scope: {
    type: Object,
    default: () => ({})
  }
})

const image = computed(() => {
  return props.scope.row.images?.square_1x ?? productPlaceholderImage
})
</script>
