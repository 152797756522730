<template>
  <div>
    <el-dropdown
      class="actions-button"
      size="large"
      split-button
      trigger="click"
      :class="{ blocked: !isAnySelected }"
      @command="handleCommand"
      @click="handleBulkEdit"
    >
      <span
        class="el-dropdown-link"
        :class="{ disabled: !isAnySelected }"
        :disabled="!isAnySelected"
      >
        {{ $t('pages.products.actions.bulk_edit') }}
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click.stop="handleBulkEdit">
            {{ $t('pages.products.actions.bulk_edit') }}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="$isFeatureEnabled('label_printing')"
            @click.stop="handlePrint"
          >
            {{ $t('pages.products.print.print_labels') }}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="$isFeatureEnabled('label_printing')"
            @click.stop="handleLegacyPrint"
          >
            {{ $t('pages.products.print.print_labels_legacy') }}
          </el-dropdown-item>
          <el-dropdown-item @click.stop="handleCreatePurchaseOrder">
            {{ $t('pages.products.actions.create_purchase_order') }}
          </el-dropdown-item>
          <el-dropdown-item
            v-if="$checkPermissions({ scopes: ['products:delete'] })"
            divided
            command="deleteRequest"
          >
            {{ $t('common.interactions.buttons.delete') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <!-- Print labels -->
    <products-print :items="selectedItems" />

    <!-- Print legacy labels -->
    <legacy-products-print :items="selectedItems" />
  </div>
</template>

<script>
import qs from 'qs'
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import { usePurchaseOrdersStore } from '@/store/purchaseOrders'
import ProductsPrint from './print'
import LegacyProductsPrint from './legacy-print'
import { useProductsStore } from '@/store/products'

export default {
  components: {
    ProductsPrint,
    LegacyProductsPrint
  },
  props: {
    selectedItems: {
      type: Array,
      required: true
    },
    allSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const purchaseOrdersStore = usePurchaseOrdersStore()
    const productsStore = useProductsStore()
    return { purchaseOrdersStore, productsStore }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    isAnySelected() {
      return this.selectedItems.length > 0
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter
      return parsedQuery || {}
    }
  },
  methods: {
    handlePrint() {
      this.$thModal.show('print-dialog')
    },
    handleBulkEdit() {
      this.productsStore.$patch({
        selectedItems: this.selectedItems,
        allSelected: this.allSelected,
        persistedFilters: {
          filters: {
            deleted: false,
            location: this.currentLocation,
            ...this.parsedQuery
          }
        }
      })
      this.$router.push({ name: 'products-bulk-edit' })
    },
    handleLegacyPrint() {
      this.$thModal.show('print-legacy-dialog')
    },
    async handleCreatePurchaseOrder() {
      if (!this.selectedItems.length) {
        this.$message({
          type: 'error',
          message: this.$t('pages.products.actions.error.no_items_selected')
        })
        return
      }

      const productIds = this.selectedItems.map((item) => item.id)
      try {
        const { data } = await th
          .suppliersProductsRelation()
          .getMap({ productId: productIds })

        const mapArray = Object.entries(data.productsToBusinessPartnersMap)

        // that means some products doesn't have a supplier
        if (mapArray.length !== this.selectedItems.length) {
          this.$message({
            type: 'error',
            message: this.$t(
              'pages.products.actions.error.one_without_supplier'
            )
          })
          return
        }

        let supplierId = null
        for (const [, value] of mapArray) {
          const [id] = value
          if (supplierId === null) {
            supplierId = id
          } else if (supplierId !== id) {
            supplierId = null
            break
          }
        }

        // that means we have more than one supplier for the selected products
        if (supplierId === null) {
          this.$message({
            type: 'error',
            message: this.$t(
              'pages.products.actions.error.more_than_one_supplier'
            )
          })
          return
        }

        this.purchaseOrdersStore.setPurchaseOrderProposal({
          products: this.selectedItems,
          supplierId
        })

        this.$router.push({
          name: 'purchase-orders-new'
        })
      } catch (error) {
        this.$logException(error, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.supplier.plural')
          })
        })
      }
    },

    async handleCommand(command) {
      switch (command) {
        case 'deleteRequest':
          this.$emit('delete-requested', this.selectedItems)
          break
        default:
          break
      }
    }
  }
}
</script>
