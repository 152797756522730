<template>
  <span class="mr-2" @click="handleImport">{{ buttonText }}</span>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import th from '@tillhub/javascript-sdk'
import FlatfileImporter from '@flatfile/adapter'
import safeGet from 'just-safe-get'
import { mapState, mapGetters, useStore } from 'vuex'
import {
  i18nOverrides,
  createOptions,
  regexURLImage,
  splitArrayIntoChunks
} from '@/utils/importer'
import { productType } from '@/utils/products'
import {
  selectFirstBranch,
  normalizePrice,
  isPriceStringNegative
} from './helpers'
import { parseBarcodeAsArray } from '@/components/inputs/barcodes-input/helpers'
import mem from 'mem'

const FLATFILE_LICENSE_KEY = process.env.VUE_APP_FLATFILE_LICENSE_KEY
const CHUNK_NUMBER = 200
// Changing this value should be enough to accept more or less attributes
const MAX_ATTRIBUTES_NUMBER = 5
const getTagByNameCache = new Map()

export default {
  name: 'ProductImporter',
  props: {
    buttonType: {
      type: String,
      default: 'secondary'
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    isVariants: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    //store
    const store = useStore()
    const clientAccountConfiguration = computed(
      () => store.getters['Config/getClientAccountConfiguration']
    )

    //i18n
    const { t, tm } = useI18n()

    //computed
    const shouldAutoGenerateCustomId = computed(
      () =>
        !!safeGet(
          clientAccountConfiguration.value,
          'products.generate_product_id'
        )
    )

    const customIdValidators = computed(() =>
      !shouldAutoGenerateCustomId.value || props.isVariants
        ? [{ validate: 'required' }]
        : []
    )

    const fields = computed(() => {
      let fields = [
        {
          key: 'product_group',
          label: t('pages.products.importer.properties.product_group_id.label')
        },
        {
          key: 'product_group_name',
          label: t(
            'pages.products.importer.properties.product_group_name.label'
          )
        },
        {
          key: 'custom_id',
          label: t('pages.products.importer.properties.custom_id.label'),
          validators: customIdValidators.value
        },
        {
          key: 'name',
          label: t('pages.products.importer.properties.name.label'),
          validators: [{ validate: 'required' }]
        },
        {
          key: 'default_price',
          label: t('pages.products.importer.properties.price.label')
        },
        {
          key: 'account',
          type: 'select',
          options: createOptions(props.resources?.accounts),
          label: t('pages.products.importer.properties.account.label'),
          validators: [
            {
              validate: 'required',
              error: t(
                'pages.products.importer.validation.revenue_account.error'
              )
            }
          ]
        },
        {
          key: 'tax',
          type: 'select',
          options: createOptions(props.resources?.taxes),
          label: t('pages.products.importer.properties.vat.label'),
          validators: [
            {
              validate: 'required',
              error: t('pages.products.importer.validation.tax.error')
            }
          ]
        }
      ]
      // Default variants fields
      // Add two attributes columns (name & value) for each supported attribute number setted on MAX_ATTRIBUTES_NUMBER
      if (props.isVariants) {
        for (
          let attributeNumber = 1;
          attributeNumber <= MAX_ATTRIBUTES_NUMBER;
          attributeNumber++
        ) {
          fields.push({
            key: `attribute_name_${attributeNumber}`,
            label: t(
              'pages.products.importer.properties.attribute_name.label',
              { attribute_number: attributeNumber }
            )
          })
          fields.push({
            key: `attribute_value_${attributeNumber}`,
            label: t(
              'pages.products.importer.properties.attribute_value.label',
              { attribute_number: attributeNumber }
            )
          })
        }
      }
      fields = fields.concat([
        {
          key: 'purchase_price',
          label: t('pages.products.importer.properties.purchase_price.label')
        },
        {
          key: 'barcodes',
          label: t('pages.products.properties.barcodes.label')
        },
        {
          key: 'tags',
          label: t('common.resource.tag.plural')
        },
        {
          key: 'stock_info',
          label: t('pages.products.importer.properties.stock_info.label')
        }
      ])
      if (!props.isVariants) {
        fields = fields.concat([
          {
            key: 'images',
            sizeHint: 2,
            label: t('pages.products.importer.properties.images.label'),
            validators: [
              {
                validate: 'regex_matches',
                regex: regexURLImage
              }
            ]
          }
        ])
      }
      fields = fields.concat([
        {
          key: 'manufacturer',
          label: t('pages.products.importer.properties.manufacturer.label')
        },
        {
          key: 'supplier',
          label: t('pages.products.importer.properties.supplier.label')
        },
        {
          key: 'brand',
          label: t('pages.products.importer.properties.brand.label')
        }
      ])
      return fields
    })

    const importer = computed(
      () =>
        new FlatfileImporter(FLATFILE_LICENSE_KEY, {
          fields: fields.value,
          type: t('pages.products.title'),
          allowInvalidSubmit: false,
          managed: true,
          disableManualInput: true,
          devMode: process.env.NODE_ENV !== 'production',
          styleOverrides: {
            primaryButtonColor: '#279ff6',
            invertedButtonColor: '#7bbaf3'
          },
          i18nOverrides: {
            de: {
              otherLocales: ['de-DE'],
              overrides: i18nOverrides({
                header: tm('pages.products.importer.flatfile.header'),
                header2: tm('pages.products.importer.flatfile.header2'),
                dropzone: {
                  accepted: '',
                  button: tm('pages.importer.flatfile.dropzone.button'),
                  description: tm(
                    'pages.products.importer.flatfile.dropzone.description'
                  )
                },
                fileTypes: tm(
                  'pages.products.importer.flatfile.dropzone.description'
                )
              })
            },
            en: {
              otherLocales: ['en-US', 'en-GB'],
              overrides: i18nOverrides({
                header: tm('pages.products.importer.flatfile.header'),
                header2: tm('pages.products.importer.flatfile.header2'),
                dropzone: {
                  accepted: '',
                  button: tm('pages.importer.flatfile.dropzone.button'),
                  description: tm(
                    'pages.importer.flatfile.dropzone.description'
                  )
                },
                fileTypes: tm(
                  'pages.products.importer.flatfile.dropzone.description'
                )
              })
            }
          }
        })
    )

    return { importer }
  },
  data() {
    return {
      locale: this.$i18n.locale,
      tags: []
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.Auth.user || '-',
      orgName: (state) => state.Auth.orgName || '-'
    }),
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    negativePriceError() {
      return {
        info: [
          {
            message: this.$t(
              'pages.products.importer.validation.price.negative.error'
            ),
            level: 'error'
          }
        ]
      }
    },
    buttonText() {
      return this.isVariants
        ? this.$t('pages.products.importer.buttons.import.variants')
        : this.$t('pages.products.importer.buttons.import.standard')
    },
    branch() {
      return selectFirstBranch(this.resources.branches || [])
    }
  },
  watch: {
    resources: {
      immediate: true,
      handler: 'initFlatFileImport'
    },
    'resources.tags'() {
      // We store the resource tags in a data prop because we need to update the list with newly created tags
      this.tags = this.resources.tags
    }
  },
  methods: {
    initFlatFileImport() {
      this.importer.setCustomer({ userId: this.userId, name: this.orgName })
      this.importer.registerRecordHook(this.validateRecords)
      this.importer.registerFieldHook('default_price', this.parsePrices)
      this.importer.registerFieldHook('purchase_price', this.parsePrices)
      this.importer.registerFieldHook(
        'product_group',
        this.validateProductGroup
      )
    },
    async handleImport() {
      this.$ampli.eventWithBaseProps('productImportButtonClicked', {
        product_type: this.isVariants
          ? 'Vartiant products'
          : 'Standart products'
      })
      try {
        const results = await this.importer.requestDataFromUser()
        this.importer.displayLoader()
        if (this.isVariants) {
          await this.createProductsWithVariants(results.data)
        } else {
          await this.createStandardProducts(results.data)
        }
        this.importer.displaySuccess(
          this.$t('pages.products.importer.messages.success')
        )
        this.$emit('refresh')
      } catch (err) {
        // Flatfile throws undefined error when user closes the importer by clicking X button.
        if (err) {
          this.$logException(err)
          this.importer.displayError(
            this.$t('pages.products.importer.messages.error')
          )
        }
      }
    },
    async createStandardProducts(products) {
      const normalizedProducts = await this.normalizeProducts(products)
      await this.createProductsByChunks(normalizedProducts)
    },
    // First create all the parent products by bulk and then create all the variants products by bulk
    async createProductsWithVariants(products) {
      const { parents, variants } = await this.normalizeProductsVariants(
        products
      )
      // Create the parent products by chunks
      const createdParents = await this.createProductsByChunks(parents)
      // Set the parent ids to the variants children
      const productVariants = this.updateVariantsParent(
        variants,
        createdParents
      )
      // Create the variants by chunks
      await this.createProductsByChunks(productVariants)
    },
    updateVariantsParent(variants, parents) {
      // Set the parent id to the variants children
      const productVariants = variants.map((variant) => {
        variant.parent = parents.find(
          (parent) => parent.custom_id === variant.parent_custom_id
        )?.id
        // We delete the parent_custom_id as is not required anymore
        delete variant.parent_custom_id
        return variant
      })
      // We return just the variants that the parent was created successfuly
      return productVariants.filter((product) => {
        return !!product.parent
      })
    },
    // Deduce the parents products from the variants, and normalize both
    async normalizeProductsVariants(products) {
      let previousCustomId = null
      const parents = []
      const variants = []
      let serial = 1
      for (let product of products) {
        const {
          stock_info,
          default_price,
          purchase_price,
          product_group_name, // property is only for UI, should not be sent to the API
          ...restProduct
        } = product

        const attributes = this.extractAttributesFromProduct(restProduct)

        const response = await this.getProductToCreate(
          productType.VARIANT,
          restProduct,
          product,
          default_price,
          purchase_price
        )

        // If we have a different custom_id, then we have a new parent product to create
        if (previousCustomId !== restProduct.custom_id) {
          // NOTE: parent product will get only the tags of the first variant - as we expect all variants to have the same tags
          parents.push(
            Object.assign({}, response, { type: productType.VARIANT_PRODUCT })
          )
          previousCustomId = restProduct.custom_id
          // We restart the serialization of the product number of the variants
          serial = 1
        }

        // Add stock info
        const stockInfo = this.getStockInfo(stock_info)
        if (stockInfo) response.stock_configuration_location = stockInfo

        // Add the serialization to the product number
        if (response.custom_id) {
          // We store the parent custom_id to be able to join them after the parent creation
          response.parent_custom_id = response.custom_id
          // Modify custom_id for the variant with the serial number
          response.custom_id = `${response.custom_id}-${serial}`
          serial++
        }

        // Add the attributes of the variants to the product
        response.attributes = attributes

        variants.push(response)
      }
      return { parents, variants }
    },
    // Takes out the attributes dynamically from the product into an object
    extractAttributesFromProduct(product) {
      const attributes = {}
      for (
        let attributeNumber = 1;
        attributeNumber <= MAX_ATTRIBUTES_NUMBER;
        attributeNumber++
      ) {
        // If we have a name and value of attribute, then add it to the object attributes
        if (
          product[`attribute_name_${attributeNumber}`] &&
          product[`attribute_value_${attributeNumber}`]
        ) {
          attributes[
            product[`attribute_name_${attributeNumber}`].toLowerCase()
          ] = product[`attribute_value_${attributeNumber}`]
        }
        // Remove the fields from the product
        delete product[`attribute_name_${attributeNumber}`]
        delete product[`attribute_value_${attributeNumber}`]
      }
      return attributes
    },
    async normalizeProducts(products) {
      const result = []
      for (let product of products) {
        const {
          stock_info,
          default_price,
          purchase_price,
          product_group_name, // property is only for UI, should not be sent to the API
          images,
          ...restProduct
        } = product

        const response = await this.getProductToCreate(
          productType.PRODUCT,
          restProduct,
          product,
          default_price,
          purchase_price
        )

        // Add stock info
        const stockInfo = this.getStockInfo(stock_info)
        if (stockInfo) response.stock_configuration_location = stockInfo

        // Add images
        if (images) response.images = { '1x': images }

        result.push(response)
      }
      return result
    },
    async getProductToCreate(
      type,
      restProduct,
      product,
      default_price,
      purchase_price
    ) {
      return {
        ...restProduct,
        product_group: this.findProductGroup(product.product_group),
        barcodes: parseBarcodeAsArray(product.barcodes),
        prices: {
          default_prices: [
            {
              amount: {
                gross: this.convertPriceToNumber(default_price)
              },
              purchase_price: this.convertPriceToNumber(purchase_price),
              currency: this.defaultCurrency
            }
          ]
        },
        supplier: {
          sku: product.supplier
        },
        manufacturer: {
          iln: product.manufacturer
        },
        type,
        tags: await this.createAndGetTagsIds(product.tags)
      }
    },
    getStockInfo(stock_info) {
      return stock_info && !isNaN(stock_info) && this.branch
        ? [
            {
              location: this.branch,
              qty: Number(stock_info),
              location_type: 'branch'
            }
          ]
        : null
    },
    // Create products by chunks and returns an array of the created products
    async createProductsByChunks(products) {
      let createdProducts = []
      const arrayOfProducts = splitArrayIntoChunks(products, CHUNK_NUMBER)
      const query = {
        query: {
          prefer_update: true, // Always preffer update over create so we don't end up with duplicates - DAS-2092
          generate_product_id:
            this.shouldAutoGenerateCustomId && !this.isVariants
        }
      }
      const thProducts = th.products()
      for (const products of arrayOfProducts) {
        try {
          const response = await thProducts.bulkCreate(products, query)
          createdProducts = createdProducts.concat(
            response?.results?.updated_products || []
          )
        } catch (err) {
          this.$logException(err)
        }
      }
      return createdProducts
    },
    convertPriceToNumber(price) {
      return normalizePrice(this.locale, price)
    },
    findProductGroup(pgId) {
      return this.resources?.product_groups.find(
        (account) => account.product_group_id === pgId
      )?.id
    },
    validateRecords(record, index, mode) {
      let out = {}
      // NOTE: for performance it's best to use registerRecordHook only for user changes and not for initial parse, for that use registerFieldHook
      if (mode !== 'change') return out
      if (
        record.product_group &&
        !this.findProductGroup(record.product_group)
      ) {
        out.product_group = {
          info: [
            {
              message: this.$t(
                'pages.products.importer.validation.product_group.error'
              ),
              level: 'error'
            }
          ]
        }
      }
      if (!record.tax) {
        out.tax = {
          info: [
            {
              message: this.$t('pages.products.importer.validation.tax.error'),
              level: 'error'
            }
          ]
        }
      }
      if (!record.account) {
        out.account = {
          info: [
            {
              message: this.$t(
                'pages.products.importer.validation.revenue_account.error'
              ),
              level: 'error'
            }
          ]
        }
      }
      if (record.default_price) {
        if (isPriceStringNegative(record.default_price)) {
          out.default_price = this.negativePriceError
        } else {
          out.default_price = {
            ...this.formatPriceWithInfo(record.default_price)
          }
        }
      }
      if (record.purchase_price) {
        if (isPriceStringNegative(record.purchase_price)) {
          out.purchase_price = this.negativePriceError
        } else {
          out.purchase_price = {
            ...this.formatPriceWithInfo(record.purchase_price)
          }
        }
      }
      return out
    },
    parsePrices(prices) {
      return prices.map(([price, index]) => {
        if (!price) return [{}, index]
        if (isPriceStringNegative(price))
          return [this.negativePriceError, index]
        return [{ ...this.formatPriceWithInfo(price) }, index]
      })
    },
    validateProductGroup(productGroups) {
      return productGroups.map(([productGroup, index]) => {
        const info =
          productGroup && !this.findProductGroup(productGroup)
            ? {
                message: this.$t(
                  'pages.products.importer.validation.product_group.error'
                ),
                level: 'error'
              }
            : {}

        return [
          {
            info: [info]
          },
          index
        ]
      })
    },
    formatPriceWithInfo(price) {
      const normalizedPrice = normalizePrice(this.locale, price)
      return {
        value: this.$formatCurrency(normalizedPrice, this.defaultCurrency),
        info: [
          {
            message: this.$t('pages.products.importer.info.price.format'),
            level: 'info'
          }
        ]
      }
    },
    normalizeTags(tags) {
      // Flatfile field returns just a single string of all the tags names that user typed
      // So we need to create from this string an array of tags and replace the existing ones with UUIDs
      const selectedTags = tags
        .split(',')
        .map((tag) => tag.trim()) //remove whitespaces
        .map((tag) => {
          const matchedTag = this.getTagByName(tag)
          return matchedTag ? matchedTag.id : tag
        })

      return [...new Set(selectedTags)] //remove duplicates
    },
    async createAndGetTagsIds(tags) {
      if (!tags) return undefined
      try {
        const tagsOps = this.normalizeTags(tags).map(async (tag) => {
          const isNewTag = !this.getTagById(tag)
          if (isNewTag) {
            const { data = {} } = await th.tags().create({ name: tag })
            this.tags.push(data)
            // We're clearing the tags cache because the tags list was updated with a new tag so cache is outdated.
            // This is only valid for the cache that is search by name, not by id.
            getTagByNameCache.clear()
            return data.id
          }
          return tag
        })

        const res = await Promise.all(tagsOps)
        return res
      } catch (err) {
        this.$logException(err)
        return undefined
      }
    },
    /*
      As this method searches for a tag by name, it expects only tag names.
      If a tag name is totally new, it will return undefined and the tag will be created.
      But in the next time the name will be passed, the tag should be already exist, but the cache remembers the previous call which returned undefined.
      Therefore we need to clear the cache when a new tag is created.
    */
    getTagByName: mem(
      function (tag) {
        return this.tags.find(
          ({ name }) => name.toLowerCase() === tag.toLowerCase()
        )
      },
      {
        cache: getTagByNameCache
      }
    ),
    getTagById: mem(function (tag) {
      return this.tags.find(({ id }) => id === tag)
    })
  }
}
</script>
