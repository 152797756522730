<template>
  <div v-loading="loading">
    <el-table :data="pageData" height="300" style="width: 100%">
      <el-table-column
        prop="location"
        :label="$t('pages.products.detail_view.branch')"
        :formatter="locationFormatter"
        show-overflow-tooltip
      />
      <el-table-column
        prop="barcodes"
        :label="$t('pages.products.properties.barcodes.label')"
        show-overflow-tooltip
        width="180"
      >
        <template #default="scope">
          <div class="flex flex-row items-center">
            <span class="truncate">{{ scope.row.barcodes || '–' }}</span>
            <el-icon
              v-if="scope.row.barcodes"
              :tabindex="0"
              class="pl-2 outline-none cursor-pointer focus:text-blue-600 hover:text-blue-600"
              :title="$t('common.interactions.copy.clipboard')"
              @click="handleBarcodeCopy(scope.row.barcodes)"
            >
              <CopyDocument
            /></el-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('pages.products.detail_view.price')"
        align="right"
        show-overflow-tooltip
        width="160"
      >
        <template #default="scope">
          <span class="default-price">
            {{ scope.row.price.default ? '(default) ' : '' }}
          </span>
          <span>{{ scope.row.price.value || '–' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="qty"
        :label="$t('pages.products.detail_view.stock')"
        align="right"
        :formatter="stockFormatter"
      />
      <el-table-column
        align="right"
        fixed="right"
        width="40"
        class-name="row-menu-container"
      >
        <template #default="scope">
          <row-menu
            :id="`row-menu-${scope.$index}`"
            ref="row-menu"
            :row="scope.row"
            :product="product"
            class="row-menu"
            @close-row-menu="() => setActiveRow(scope.$index, scope.row)"
            @edit-stock-clicked="handleStockClicked"
          />
        </template>
      </el-table-column>
    </el-table>

    <th-pagination
      v-if="tableData && tableData.length > 0"
      class="pt-5 pb-0"
      :page-size="pageSizes.inline"
      :total="tableData.length"
      :current-page="page"
      @current-change="page = $event"
      @size-change="sizeChange"
    />

    <stock-edit-modal
      :visible="editStockVisible"
      @close-edit-stock="closeEditStock"
      @save-stock="handleStockSave"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import { findSellingPrice, formatStock } from './helpers/table-helpers'
import RowMenu from './components/row-menu'
import StockEditModal from './components/stock-edit-modal'
import { copyToClipboard } from '@/utils/dom'

export default {
  components: {
    RowMenu,
    StockEditModal
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      tableData: null,
      payload: {},
      loading: false,
      activeRowMenu: null,
      editStockVisible: false,
      currentItem: {}
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      pageSizes: 'Config/getPageSizes',
      locale: 'Config/getLocale'
    }),

    pageData() {
      return this.tableData?.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    }
  },
  async created() {
    document.addEventListener('click', this.closeOnOutsideClick)

    this.load()
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeOnOutsideClick)
  },
  methods: {
    async load() {
      try {
        await this.fetch()
      } catch (error) {
        return this.$log.debug(
          'products-quick-view: failed to fetch data after stock update',
          error
        )
      }

      this.getTableData()
    },
    async fetch() {
      this.loading = true

      try {
        const { data } = await th
          .products()
          .getDetails(this.product.id, { original_product: true })
        this.payload = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    getTableData() {
      const { all_stock: allStock } = this.payload

      if (!allStock) return null

      const result = allStock
        .map((stock) => {
          const item = {
            ...stock,
            price: this.getPrice(stock.location.id),
            barcodes: this.product.barcodes?.join(', ')
          }
          return item
        })
        .filter((item) => {
          // we filter locally for branch context items
          if (
            this.currentLocation &&
            safeGet(item, 'location.id') === this.currentLocation
          ) {
            return true
          } else if (this.currentLocation) {
            return false
          }
          return true
        })

      this.tableData = result
    },
    getPrice(branchId) {
      const result = {
        default: false,
        value: null
      }

      if (!this.product.prices) return result

      const {
        branch_prices: branchPrices,
        default_prices: defaultPrices
      } = this.product.prices

      if (branchPrices && Array.isArray(branchPrices) && branchPrices.length) {
        const branchPrice = branchPrices.find((p) => p.branch === branchId)
        const sellingPrice = findSellingPrice.call(
          this,
          branchPrice && branchPrice.prices,
          this.defaultCurrency
        )

        if (sellingPrice) {
          result.value = sellingPrice
          return result
        }
      }

      if (
        defaultPrices &&
        Array.isArray(defaultPrices) &&
        defaultPrices.length
      ) {
        const sellingPrice = findSellingPrice.call(
          this,
          defaultPrices,
          this.defaultCurrency
        )
        if (sellingPrice) {
          result.default = true
          result.value = sellingPrice
        }
      }

      return result
    },
    stockFormatter(row, column, cellValue, index) {
      return this.product.stockable !== false
        ? formatStock.call(this, row.qty)
        : '–'
    },
    locationFormatter(row, column, cellValue, index) {
      return safeGet(row, 'location.name') || safeGet(row, 'location.id') || '–'
    },
    handleRowMenuClick(index, row) {
      this.setActiveRow(index)
      this.currentItem = row
    },
    setActiveRow(index) {
      this.activeRowMenu = this.activeRowMenu === index ? null : index
    },
    closeOnOutsideClick(e) {
      if (e.target.className !== 'el-icon-more') this.activeRowMenu = null
    },
    handleStockClicked(row) {
      this.showEditStock()
      this.currentItem = row
    },
    showEditStock() {
      this.editStockVisible = true
    },
    closeEditStock() {
      this.editStockVisible = false
    },
    async handleStockSave({ location, qty }) {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.stock.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.stock.singular')
      })

      const reqOptions = {
        productId: this.product.id,
        body: {
          location: this.currentItem.location.id,
          qty
        }
      }

      try {
        await th.products().bookStock(reqOptions)
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        return this.$logException(err, {
          message: errorMessage
        })
      }

      try {
        await this.fetch()
      } catch (error) {
        return this.$log.debug(
          'products-quick-view: failed to fetch data after stock update',
          error
        )
      }

      this.getTableData()

      this.$emit('refresh-datatable')
    },
    async handleBarcodeCopy(barcode) {
      try {
        await copyToClipboard(barcode)
        this.$message({
          type: 'success',
          message: this.$t('pages.products.detail_view.copy_barcode.message')
        })
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message
        })
      }
    },
    sizeChange(value) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageSizes.inline',
        value
      })
    }
  }
}
</script>

<style scoped>
.default-price {
  color: lightgrey;
}

.row-menu {
  z-index: 100;
}

.row-menu :deep(.el-dropdown) {
  vertical-align: baseline;
}

.el-icon-more {
  cursor: pointer;
}
</style>
