<template>
  <div
    class="w-full h-full flex flex-col bg-th-navigation"
    data-testid="products-empty"
  >
    <div class="flex justify-between items-center py-6 pr-6 pl-8">
      <div
        class="h-full flex flex-col flex-end font-bold leading-7 text-lg"
        :class="{ italic: !isWhiteLabel }"
      >
        <div>{{ $t('pages.products.empty.text.title') }}</div>
        <div>{{ $t('pages.products.empty.text.sub') }}</div>
      </div>
      <div>
        <product-importer
          v-if="$checkPermissions({ scopes: ['products:bulk_import'] })"
          :resources="resources"
          @refresh="refresh"
        />
        <el-button
          v-if="computedButtons.length > 0"
          split-button
          class="ml-0"
          type="primary"
          icon="Plus"
          @click="computedButtons[0].clickHandler"
        >
          <span data-testid="products-add-new">
            {{ computedButtons[0].label }}
          </span>
        </el-button>
      </div>
    </div>
    <div class="h-full flex min-h-0 min-w-0 items-end justify-center">
      <img
        :src="productsListEmpty"
        alt="products-list-empty"
        style="max-width: 80%; max-height: 71vh"
      />
    </div>
    <div class="th-shadow-top h-20" />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import productsListEmpty from '@/assets/illustrations/products-list-empty.svg'
import ProductImporter from './components/product-importer'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    ProductImporter
  },
  props: {
    templateHref: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resources: {},
      productsListEmpty,
      buttons: [
        {
          scopes: ['products:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.forms.labels.new'),
          clickHandler: this.handleNew
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  created() {
    this.fetchResources()
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'products-new' })
    },
    async fetchResources() {
      const {
        branchesV1 = [],
        product_groups = [],
        taxes = [],
        accounts = []
      } = await this.$resourceFetch('product_groups', 'branchesV1', 'taxes', {
        resource: 'accounts',
        handler: () => th.accounts().getAll({ type: 'revenue' })
      })
      this.resources = {
        product_groups,
        branches: branchesV1,
        taxes,
        accounts
      }
    }
  }
}
</script>

<style scoped>
.th-shadow-top {
  box-shadow: 0px -4px 4px rgba(167, 171, 177, 0.15);
}
</style>
