import compose from 'just-compose'
import isEmpty from 'just-is-empty'

/**
 * Doing the best to infer from a price if it's a comma or dot decimal number.
 * In our supported locales, only en-US has dot decimal in its prices.
 * But knowing the locale not enough in order to make an educated guess about user input of price, so we also want to check the actual placement of the commas and dots in the price.
 * @param {String} locale
 * @param {String} price e.g. "10.999,24 €", "$1,000.99", "1 234,49"
 * @returns {String}
 */
export const normalizePrice = (locale, price = '') => {
  const stringifyPrice = isCommaDecimal(locale, price)
    ? price
        .replace(/[^0-9,]/g, '') //remove everything but numbers and commas
        .replace(/,/g, '.') //replace comma with decimal dot
    : price.replace(/[^0-9.]/g, '') //remove everything but numbers and dots

  return parseFloat(stringifyPrice)
}

const keepOnlySeparators = (str) => str.replace(/[^.,]/g, '')

const dedupe = (arr) => [...new Set(arr)]

const last = (arr) => arr[arr.length - 1]

const isComma = (char) => char === ','

export const isCommaLast = compose(
  keepOnlySeparators,
  (str) => str.split(''),
  dedupe,
  last,
  isComma
)

const isCommaDecimal = (locale, price) =>
  isCommaLast(price) ?? locale !== 'en-US'

export const selectFirstBranch = (branches = []) =>
  branches.sort((a, b) => a.branch_number - b.branch_number)?.[0]?.id

export const splitArrayIntoChunks = (arr, chunkSize) => {
  const chunks = [],
    n = arr.length
  let i = 0
  while (i < n) {
    chunks.push(arr.slice(i, (i += chunkSize)))
  }
  return chunks
}

/**
 * Determines if the provided price string is a negative number after removing non numeric characters.
 * @param {String} price e.g. "10.999,24 €", "$ -1,000.99", "-1 234,49"
 * @returns {Boolean}
 */
export const isPriceStringNegative = (price) => {
  if (typeof price !== 'string') return false
  const cleanedPrice = price.match(/[\d.,-]+/g)?.[0]
  if (!cleanedPrice) return false
  return cleanedPrice.startsWith('-')
}
