<template>
  <div>
    <el-dropdown trigger="click">
      <el-icon><More /></el-icon>
      <template #dropdown>
        <el-dropdown-menu class="menu-container">
          <el-dropdown-item
            v-for="option in options"
            :key="option.value"
            :class="getClasses(option)"
            :disabled="option.disabled"
            @click="handleMenuItemClick(option.value)"
          >
            <span class="menu-text" v-text="option.label" />
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    isProductWithVariants() {
      return this.product.type === 'variant_product'
    },
    options() {
      const menuRoutableProduct = {
        label: this.$t('pages.products.detail_view.row-menu.edit_product'),
        value: 'edit-product',
        disabled: false
      }

      const arr = [
        ...(this.isProductWithVariants ? [menuRoutableProduct] : []),
        {
          label: this.$t('pages.products.detail_view.row-menu.print_label'),
          value: 'print-label',
          disabled: true
        }
      ]

      if (this.product.stockable !== false) {
        arr.push({
          label: this.$t('pages.products.detail_view.row-menu.edit_stock'),
          scopes: ['products:create_stock_booking'],
          value: 'stock',
          disabled: false
        })
      }

      return arr.filter((opt) =>
        opt.scopes ? this.$checkPermissions({ scopes: opt.scopes }) : true
      )
    }
  },
  methods: {
    getClasses(option) {
      return ['menu-item', !option.disabled ? 'enabled' : 'disabled']
    },
    handleMenuItemClick(type) {
      if (type === 'stock') {
        this.$emit('edit-stock-clicked', this.row)
      }

      if (type === 'edit-product' && this.row.id) {
        this.$router.push({
          name: 'products-edit',
          params: { id: this.row.id }
        })
      }

      this.$emit('close-row-menu')
    }
  }
}
</script>

<style scoped>
.menu-container {
  border-radius: 5px;
  border: 1px lightgrey solid;
  padding: 5px 0;
  background: white;
  z-index: 1;
  right: 0;
  width: fit-content;
  text-align: left;
}

.disabled {
  color: lightgrey;
  pointer-events: none;
}

.menu-text {
  margin: 0 5px;
}

.menu-item.enabled {
  cursor: pointer;
}

.menu-item.enabled:hover {
  background-color: var(--light-blue);
  color: #279ff6;
}

.el-icon-more {
  cursor: pointer;
}
</style>
