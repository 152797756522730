import sortBy from 'just-sort-by'

export function getSortedOptions(
  items = [],
  options = { value: 'id', label: 'name' }
) {
  return sortBy(
    items.map((item) => {
      return {
        label: item[options.label],
        value: item[options.value]
      }
    }),
    'label'
  )
}
