<template>
  <th-modal
    :title="$t('pages.products.print.print_labels')"
    width="700px"
    name="print-dialog"
  >
    <template v-if="unprintables.length > 0" #subtitle>
      <span class="mr-1">
        <el-tooltip
          :content="$t('pages.products.all.print.cannot_print_tooltip')"
        >
          <el-icon style="color: #f56c6c"><Warning /></el-icon>
        </el-tooltip>
        {{ $t('pages.products.print.cannot_print') }}
      </span>
      <span class="font-bold">{{ unprintables.join(', ') }}</span>
    </template>

    <!-- Table -->
    <el-table class="w-full shadow-sm rounded" :data="printList">
      <el-table-column type="expand">
        <template #default="props">
          <pre>{{ JSON.stringify(props.row, null, 2) }}</pre>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('pages.products.print.headers.name')"
        prop="name"
      />
      <el-table-column
        :label="$t('pages.products.print.headers.quantity')"
        prop="quantity"
      >
        <template #default="scope">
          <el-input v-model="scope.row.quantity" type="number" />
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <!-- Cancel -->
      <el-button @click="close">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Print -->
      <el-button
        type="primary"
        :disabled="!printList || !printList.length"
        @click="preparePrinterSelectionDialog"
      >
        {{ $t('common.interactions.buttons.print') }}
      </el-button>
    </template>

    <!-- Select a printer -->
    <th-modal
      name="printers"
      :title="$t('pages.products.print.select_printer')"
    >
      <!-- Printers -->
      <el-radio
        v-for="printer in printers"
        :key="printer.name"
        v-model="selectedPrinter"
        :label="printer"
        border
      >
        {{ printer.name }}
      </el-radio>

      <!-- Print immediatley -->
      <el-checkbox v-model="printNow" class="mt-10">
        {{ $t('pages.products.print.schedule_print') }}
      </el-checkbox>

      <template #footer>
        <!-- Cancel -->
        <el-button @click="$thModal.hide('printers')">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>

        <!-- OK -->
        <el-button :disabled="!selectedPrinter" type="primary" @click="print">
          {{ $t('common.interactions.buttons.ok') }}
        </el-button>
      </template>
    </th-modal>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'

export default {
  name: 'ProductsPrint',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    printList: [], // printable items
    unprintables: [], // names of products that can't be printed
    printers: [],
    selectedPrinter: undefined,
    loading: false,
    printNow: true
  }),
  watch: {
    items: function (newVal) {
      // TODO: review for infinite loop
      this.updatePrintList(newVal)
    }
  },
  mounted() {
    this.updatePrintList(this.items)
  },
  methods: {
    updatePrintList(items) {
      this.printList = []
      this.unprintables = []
      items.map((item) => {
        // deciding which items can be printed. currently, only the item type is important
        if (
          ['product', 'linked_product', 'variant', 'linked'].includes(item.type)
        ) {
          this.printList.push({ ...item, quantity: 1 })
        } else {
          this.unprintables.push(item.name)
        }
      })
    },
    close() {
      this.$emit('close-requested')
      this.$thModal.hide('print-dialog')
    },
    /**
     * Creates ZPL-instructions representing a label for the given product
     * @param item - product item
     * @returns {string} - ZPL instructions as string
     */
    createLabel(item) {
      /*
      based on following legacy code:

      label_sequence = “^XA^CI28”
      label_sequence = label_sequence  + “^FO280,45^ADN,26,10^FD” +   Left (rs.Field (“name”).StringValue, 10) + “^FS”
      label_sequence = label_sequence  + “^FO480,45^ADN,48,10^FD”+ Session.tocurr  (Session.StammDB.get_v1_gross (sale_id))+ “^FS”
      if option <> “” then
        label_sequence = label_sequence  + “^FO280,70^ADN,26,10^FD” + option + “^FS”
      else
        label_sequence = label_sequence  + “^FO280,70^ADN,26,10^FD” + “-” + “^FS”
      end
      label_sequence = label_sequence  + “^FO250,100^BY2^BCN,40,Y,N,N^FD” + “*S” +  Session.ins_leftnull (rs.Field (“custom_id”).StringValue, 9) + “^FS”
      label_sequence = label_sequence  + “^XZ”
      */

      const options = item.attributes
        ? Object.entries(item.attributes)
            .map((e) => e.join(': '))
            .join(', ')
        : '-'

      return [
        '^XA^CI28',
        `^FO280,45^ADN,26,10^FD${item.name.substring(0, 16)}^FS`, // item name
        `^FO480,45^ADN,48,10^FD${this.getFormattedPrice(item)}^FS`, // price
        `^FO280,70^ADN,26,10^FD${options.substring(0, 16)}^FS`, // item options
        `^FO230,100^BY2^BCN,40,Y,N,N^FD*S${item.custom_id.padStart(13, 0)}^FS`, // barcode
        '^XZ'
      ].join()
    },

    /**
     * Queries and resolves printers attached to the current account
     * @returns {Array.<Object>} - array of printers
     */
    async getAttachedPrinters() {
      let data
      try {
        const { configurations } = await this.$resourceFetch({
          resource: 'configurations',
          query: { owner: 'self' }
        })
        data = configurations
      } catch (e) {
        return []
      }

      const printerIds =
        safeGet(data[0], 'label_printer.attached_devices') || []
      // resolve printers
      let printerObjects
      try {
        printerObjects = await Promise.all(
          printerIds.map((printerId) =>
            th
              .print()
              .printers()
              .get(printerId)
              .then(
                ({ data }) => data,
                (e) => undefined // make unresolved printers undefined
              )
          )
        )
        printerObjects = printerObjects.filter((p) => p) // remove printers that could not be resolved
      } catch (e) {
        this.$logException(e, {
          trackError: false,
          message: `failed to resolve printers from IDs: ${printerIds}`
        })
        return []
      }
      return printerObjects
    },

    /**
     * Gathers attached printers and shows the printer selection dialog on success
     * @returns {Promise<void>}
     */
    async preparePrinterSelectionDialog() {
      this.selectedPrinter = undefined
      this.printers = []
      this.loading = true
      this.printers = await this.getAttachedPrinters()
      this.loading = false
      if (!this.printers || !this.printers.length) {
        this.$thModal.hide('printers')
        this.$notify.error('No attached printers found')
      } else {
        this.$thModal.show('printers')
      }
    },

    /**
     * Creates a print job for each product item
     * @returns {Promise<Object[]>}
     */
    async print() {
      this.$thModal.hide('printers')
      const jobs = this.printList.map((product) => ({
        printer: this.selectedPrinter.id,
        client_id: this.selectedPrinter.client_id,
        type: 'RAW',
        data: this.createLabel(product),
        options: {
          copies: product.quantity
        }
      }))

      return Promise.all(
        jobs.map((job) =>
          th.print().jobs().create(job, { immediate: this.printNow })
        )
      )
    },
    /**
     * Formats the price. Based on a formatter implemented in `../all.vue`
     * @param item - product item
     * @returns {string}
     */
    getFormattedPrice(item) {
      if (
        item.prices &&
        item.prices.default_prices &&
        Array.isArray(item.prices.default_prices)
      ) {
        const currentCurrencyPrice = item.prices.default_prices.find((item) => {
          return item.currency === 'EUR'
        })

        if (
          !currentCurrencyPrice ||
          !currentCurrencyPrice.amount ||
          !Number.isFinite(currentCurrencyPrice.amount.gross)
        )
          return '-'
        return this.$formatCurrency(currentCurrencyPrice.amount.gross, 'EUR')
      }

      return '-'
    }
  }
}
</script>
