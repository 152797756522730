<template>
  <div v-loading="loading">
    <el-table
      :data="pageData"
      class="parent-table"
      style="width: 100%"
      height="300"
    >
      <el-table-column
        prop="name"
        :label="$t('pages.products.detail_view.name')"
        width="180"
        show-overflow-tooltip
      />
      <el-table-column
        prop="custom_id"
        :label="$t('pages.products.detail_view.custom_id')"
        width="80"
        show-overflow-tooltip
      />
      <el-table-column
        prop="attribute_string"
        :label="$t('pages.products.detail_view.attributes')"
        width="120"
        show-overflow-tooltip
      />
      <el-table-column
        prop="barcodes"
        :label="$t('pages.products.properties.barcodes.label')"
        show-overflow-tooltip
        width="180"
      >
        <template #default="scope">
          <div class="flex flex-row items-center">
            <span class="truncate">{{ scope.row.barcodes || '–' }}</span>
            <el-icon
              v-if="scope.row.barcodes"
              :tabindex="0"
              class="pl-2 outline-none cursor-pointer focus:text-blue-600 hover:text-blue-600"
              :title="$t('common.interactions.copy.clipboard')"
              @click="handleBarcodeCopy(scope.row.barcodes)"
            >
              <CopyDocument
            /></el-icon>
          </div> </template
      ></el-table-column>
      <el-table-column
        prop="price"
        :label="$t('pages.products.detail_view.price')"
        align="right"
        width="120"
        show-overflow-tooltip
      />
      <el-table-column
        prop="stock"
        :label="$t('pages.products.detail_view.stock')"
        align="right"
        show-overflow-tooltip
      />
      <el-table-column
        align="right"
        fixed="right"
        width="40"
        class-name="row-menu-container"
      >
        <template #default="scope">
          <!-- @click="handleRowMenuClick(scope.$index, scope.row)" -->
          <row-menu
            :id="`row-menu-${scope.$index}`"
            ref="row-menu"
            :row="scope.row"
            :product="product"
            class="row-menu"
            @close-row-menu="() => setActiveRow(scope.$index, scope.row)"
            @edit-stock-clicked="handleStockClicked"
          />
        </template>
      </el-table-column>
    </el-table>

    <th-pagination
      v-if="tableData && tableData.length > 0"
      class="pt-5 pb-0"
      :page-size="pageSizes.inline"
      :total="tableData.length"
      :current-page="page"
      @current-change="page = $event"
      @size-change="sizeChange"
    />
    <stock-edit-modal
      :visible="editStockVisible"
      :show-locations="true"
      @close-edit-stock="closeEditStock"
      @save-stock="handleStockSave"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import get from 'just-safe-get'
import { findSellingPrice, formatStock } from './helpers/table-helpers'
import RowMenu from './components/row-menu'
import StockEditModal from './components/stock-edit-modal'
import { copyToClipboard } from '@/utils/dom'

export default {
  components: {
    RowMenu,
    StockEditModal
  },
  filters: {
    dash(val) {
      return val || '-'
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      tableData: null,
      payload: [],
      loading: false,
      activeRowMenu: '',
      editStockVisible: false,
      currentItem: {}
    }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      pageSizes: 'Config/getPageSizes',
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation'
    }),

    pageData() {
      return this.tableData?.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    }
  },
  async created() {
    document.addEventListener('click', this.closeOnOutsideClick)

    this.load()
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeOnOutsideClick)
  },
  methods: {
    async load() {
      try {
        await this.fetch()
      } catch (error) {
        return this.$log.debug(
          'products-quick-view: failed to fetch data after stock update',
          error
        )
      }

      this.getTableData()
    },
    async fetch() {
      this.loading = true

      try {
        const { data } = await th.products().getChildrenDetails(this.product.id)
        this.payload = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    getTableData() {
      const result = this.payload.map((child) => {
        const data = {
          ...child,
          stock: this.product.stockable !== false ? this.getStock(child) : '–',
          price: this.getPrice(child),
          barcodes: this.product.barcodes?.join(', ')
        }

        const { attributes = {} } = child
        const attributeString = Object.keys(attributes)
          .map((name) => attributes[name])
          .join(' | ')

        if (attributeString) data.attribute_string = attributeString

        return data
      })

      this.tableData = result
    },
    getPrice(child) {
      if (!child.prices) return '-'

      const {
        branch_prices: branchPrices,
        default_prices: defaultPrices
      } = child.prices

      const hasBranchPrices =
        branchPrices && Array.isArray(branchPrices) && branchPrices.length

      if (hasBranchPrices)
        return this.$t('pages.products.detail_view.per_branch')

      if (
        defaultPrices &&
        Array.isArray(defaultPrices) &&
        defaultPrices.length
      ) {
        return (
          findSellingPrice.call(this, defaultPrices, this.defaultCurrency) ||
          '-'
        )
      }
    },
    handleRowMenuClick(index, row) {
      this.setActiveRow(index)
      this.currentItem = row
    },
    setActiveRow(index, row) {
      this.activeRowMenu = this.activeRowMenu === index ? null : index
    },
    closeOnOutsideClick(e) {
      if (e.target.className !== 'el-icon-more') this.activeRowMenu = null
    },
    handleStockClicked(row) {
      this.showEditStock()
      this.currentItem = row
    },
    showEditStock() {
      this.editStockVisible = true
    },
    closeEditStock() {
      this.editStockVisible = false
    },
    async handleStockSave({ location, qty }) {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.stock.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.stock.singular')
      })

      const reqOptions = {
        productId: this.currentItem.id,
        body: {
          location,
          qty
        }
      }

      try {
        await th.products().bookStock(reqOptions)
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        return this.$logException(err, {
          message: errorMessage
        })
      }

      try {
        await this.fetch()
      } catch (error) {
        return this.$log.debug(
          'products-quick-view: failed to fetch data after stock update',
          error
        )
      }

      this.getTableData()

      this.$emit('refresh-datatable')
    },
    sizeChange(value) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageSizes.inline',
        value
      })
    },
    async handleBarcodeCopy(barcode) {
      try {
        await copyToClipboard(barcode)
        this.$message({
          type: 'success',
          message: this.$t('pages.products.detail_view.copy_barcode.message')
        })
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message
        })
      }
    },
    getStock(item) {
      if (this.currentLocation) {
        const stock = item.all_stock?.find(
          (stock) => get(stock, 'location.id') === this.currentLocation
        )?.qty

        return formatStock.call(this, stock)
      }
      return formatStock.call(this, item.stock_sum)
    }
  }
}
</script>

<style scoped>
.el-icon-more {
  cursor: pointer;
}

.parent-table :deep(.el-table__header .cell) {
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
