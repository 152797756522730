<template>
  <el-dropdown class="importer">
    <el-button class="mr-2">
      <svgicon
        :src="require('@/assets/icons/th-icon-upload.svg')"
        :style="{ height: '20px', width: '20px' }"
        class="mr-2 fill-current"
      />
      <span>{{ $t('pages.products.importer.buttons.import.button') }}</span>
      <arrow-down />
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item>
          <generic-product-importer
            class="button-text-color"
            :resources="resources"
            @refresh="refresh"
          />
        </el-dropdown-item>
        <el-dropdown-item>
          <generic-product-importer
            class="button-text-color"
            :resources="resources"
            is-variants
            @refresh="refresh"
          />
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import GenericProductImporter from './generic-product-importer.vue'

export default {
  name: 'ProductImporter',
  components: {
    GenericProductImporter
  },
  props: {
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.importer :deep(button) {
  color: var(--text-default-color);
}
</style>
